
.subject-title-tttyyy {
    max-width: 40% !important;
    * {
        color: #333 !important;
        background: transparent !important;
        text-decoration: none !important;
    }
    img {
        max-width: 100px !important;
        max-height: 100px !important;
    }
}
